<template>
  <div>
    <v-card class="mb-4">
      <v-card-title>
        {{ $t('ListExamTitle') }}:
      </v-card-title>
      <v-card-text>
        <v-form>
          <v-row>
            <v-col class="pb-0">
              <DatePicker
                v-model="date"
                :label="$t('Date Start')"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col class="pb-0">
              <DatePicker
                v-model="dateEnd"
                :label="$t('Date End')"
              />
            </v-col>
          </v-row>
          <!-- Cafe Manager -->
          <v-row>
            <v-col class="pb-0">
              <v-text-field
                v-model="search"
                dense
                outlined
                hide-details="auto"
                :label="$t('PhoneOrName')"
              >
              </v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-btn
                color="primary"
                block
                @click="handleGetData"
              >
                Search
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>
    <v-card
      v-infinite-scroll="loadMore"
      infinite-scroll-disabled="isBusy"
    >
      <v-list v-if="getListExamData.length > 0">
        <template v-if="getLoadingWait">
          <v-progress-linear
            indeterminate
            color="primary"
          ></v-progress-linear>
        </template>
        <template
          v-for="(item, index) in getListExamData"
        >
          <v-list-item

            :key="item.id_examination"
            :to="{name: 'staff-town-infirmary-checkup-edit', params: {id: `${item.id_user}-${item.id_examination}`}}"
          >
            <v-list-item-avatar>
              <v-img
                alt
                :src="item.face_image_url || require('@/assets/images/avatars/1.png')"
              ></v-img>
            </v-list-item-avatar>

            <v-list-item-content>
              <v-list-item-title class="font-weight-bold">
                {{ item.full_name || '-' }}
              </v-list-item-title>
              <v-list-item-subtitle>
                <span class="text--primary">
                  {{ item.phone || '-' }}
                </span><br />
                <span class="text-body-2">{{ $t('CheckUpAt') }}: {{ formatDate(item.created_at*1000) }}</span>
              </v-list-item-subtitle>
            </v-list-item-content>

            <v-list-item-action>
              <v-btn
                icon
              >
                <v-icon>
                  {{ mdiClipboardCheckOutline }}
                </v-icon>
              </v-btn>
            </v-list-item-action>
          </v-list-item>

          <v-divider
            v-if="index < getListExamData.length - 1"
            :key="`divider-${item.id_examination}`"
            :inset="item.inset"
          ></v-divider>
        </template>
      </v-list>
      <v-card-text
        v-else
      >
        {{ $t('NoData') }}
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
import DatePicker from '@/components/DatePicker.vue'
import Bus from '@/utils/bus'
import { mdiClipboardCheckOutline, mdiMagnify } from '@mdi/js'
import _ from 'lodash'
import { mapActions, mapGetters } from 'vuex'

export default {
  components: { DatePicker },
  data() {
    return {
      date: '',
      dateEnd: '',
      search: '',
      mdiClipboardCheckOutline,
      mdiMagnify,
      busy: false,
      page: 1,
      limit: 10,
    }
  },
  computed: {
    ...mapGetters('town', ['getListExamData', 'getLoadingWait', 'getIsMore']),
    paramsExamList() {
      return {
        from_date: this.date,
        to_date: this.dateEnd,
        keyword: this.search,
        page: this.page,
        limit: this.limit,
      }
    },
    isBusy() {
      return this.getLoadingWait || !this.getIsMore
    },
  },
  created() {
    this.handleGetData()
    Bus.$on('haveMessage', this.handleMsg)
  },
  destroyed() {
    Bus.$off('haveMessage', this.handleMsg)
  },

  methods: {
    ...mapActions('town', ['fetchExamList', 'loadMoreExamList']),
    handleMsg(codeEvent) {
      if (codeEvent === 'town_infirmary_001') {
        this.handleGetData()
      }
    },
    handleGetData: _.debounce(function() {
      this.fetchExamList({ ...this.paramsExamList, page: 1 })
    }, 500),
    loadMore: _.debounce(function() {
      // this.busy = true
      this.loadMoreExamList({ ...this.paramsExamList, page: this.page + 1 }).then(() => {
        this.page += 1
      })
    }, 500),
  },
}
</script>
